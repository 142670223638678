
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioDespesaChave extends PageBase { 
    service = new RelatorioService();
    item: Model[] = [];
    overlay: boolean = false; 
    filter:any = {};
 
    mounted() {
    this.filter = this.$route.query; 
    this.overlay = true;  

    this.service.ListarTaxaAdm(this.filter.empreendimentoId, this.filter.empresaId, this.filter.isBaixaAutomatica, this.filter.dataVencimentoInicial, this.filter.dataVencimentoFinal, this.filter.dataPagamentoInicial, this.filter.dataPagamentoFinal).then(
        res => {
            this.item = res.data;
        }, 
        err => {
            if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } 
            else {
                this.$swal("Aviso", err.response.data, "error");
            }
        } 
        ).finally(() =>{this.overlay = false});
    } 
}

class Model {
    totalValorTaxaAdm:number = 0;
    totalValorPago:number = 0;
    parcelas = new ModelParcelas();
}

class ModelParcelas {
    numeroSequenciaContrato:string = ""
    empresaNome: string = "";
    empreendimentoNome: string = "";
    grupoNome:string = "";
    unidadeNome:string = ""; 
    parcelaNumero:number = 0; 
    valorParcela:number = 0;
    valorPagoParcela:number =0 
    jurosParcelaBaixa:number = 0;  
    multaParcelaBaixa:number = 0;
    descontosParcelaBaixa:number = 0;   
    vencimentoParcela:string ='';   
    dataPagamentoParcela:string ='';  
    valorTaxaAdm:number = 0;

    valorCorretor:number = 0;
    valorTaxaAdmMenosCorretor:number = 0;
}
